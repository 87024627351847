import React, { useState, useEffect } from "react";
import styles from "./Inicio.module.css";
import ProjectCard from "components/ProjectCard";
import Experiences from "components/Experiences";
import Contact from "components/Contact";
import { getDatabase, ref, get } from "firebase/database";
import firebaseApp from "./firebaseConfig";

export default function Inicio() {
  const [projects, setProjects] = useState([]);

  const db = getDatabase(firebaseApp);

  useEffect(() => {
    let isMounted = true;
    const getProjects = async () => {
      try {
        const dbRef = ref(db);
        const snapshot = await get(dbRef);
        const firebaseData = snapshot.val();
        const projectsArray = Object.values(firebaseData);

        if (isMounted) {
          setProjects(projectsArray);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getProjects();

    return () => {
      isMounted = false;
    };
  });

  return (
    <section>
      {
        <>
          <article
            className={`${styles.container} animate__animated animate__fadeIn animate__delay-1s`}
          >
            <h3 className={styles.titulo}>Projetos</h3>
            <ul className={styles.projects}>
              {projects.map((project) => (
                <li key={project.id}>
                  <ProjectCard project={project} />
                </li>
              ))}
            </ul>
          </article>

          <Experiences />
          <Contact />
        </>
      }
    </section>
  );
}
