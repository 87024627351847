import styles from "./Experiences.module.css";
import experiences from "json/experiences.json";

export default function Experiences() {
  return (
    <div className={styles.banner}>
      <h3 className={`${styles.titulo}`}>Experiências</h3>

      <ul className={`${styles.experiences}`}>
        {experiences.map((experience) => (
          <li className={`${styles.experience}`} key={experience.id}>
            <img
              className={styles.logo}
              src={`/assets/experiences/${experience.id}.png`}
              alt={`Logo ${experience.titulo}`}
            ></img>
            <h4 className={styles.subtitulo}>{experience.titulo}</h4>
          </li>
        ))}
      </ul>
    </div>
  );
}
