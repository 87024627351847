import React, { useState } from "react";
import styles from "./Menu.module.css";
import MenuLink from "../MenuLink";
import { Link, useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function MenuComponent() {
  const localization = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className={styles.header}>
      <div
        className={`animate__animated ${
          localization.pathname !== "/" ? "animate__slideInDown" : styles.hide
        }`}
      >
        <div className={styles.menuMobile}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>
              <Link to="/">Inicio</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to="/sobremim">Sobre Mim</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to="/contato">Fale Comigo</Link>
            </MenuItem>
          </Menu>
        </div>
        <nav className={`${styles.navegacao}`}>
          <MenuLink to="/">Inicio</MenuLink>
          <MenuLink to="/sobremim">Sobre Mim</MenuLink>
          <MenuLink to="/contato">Fale Comigo</MenuLink>
        </nav>
      </div>
    </header>
  );
}
