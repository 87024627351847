import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import AppRoutes from "./routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "animate.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fb4570",
    },
    secondary: {
      main: "#fb6b90",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppRoutes />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
