import styles from "./Footer.module.css";
import { ReactComponent as MarcaRegistrada } from "assets/marca_registrada.svg";
import linkedinLogo from "assets/icons8-linkedin.svg";
import githubLogo from "assets/icons8-github.svg";

export default function Footer() {
  return (
    <footer className={styles.rodape}>
      <p className={styles.author}>
        <MarcaRegistrada /> Desenvolvido por Carol Bastos
      </p>

      <div className={styles.link}>
        <a
          href="https://www.linkedin.com/in/carol-bastos/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedinLogo} alt="LinkedIn" />
        </a>
        <a
          href="https://github.com/CarolBastos"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={githubLogo} alt="GitHub" />
        </a>
      </div>
    </footer>
  );
}
