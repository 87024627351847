import styles from "./ProjectCard.module.css";
import ButtonMain from "components/ButtonMain";

export default function ProjectCard({ project }) {
  return (
    <a target="blank" href={project.link}>
      <div className={styles.project}>
        <img
          className={styles.capa}
          src={`/assets/posts/${project.id}/capa.jpg`}
          alt="Imagem de capa do project"
        ></img>
        <div className={styles.wrap}>
          <h4 className={styles.titulo}>{project.titulo}</h4>
          <h5 className={styles.texto}>{project.texto}</h5>
        </div>
        <ButtonMain>Ver Mais</ButtonMain>
      </div>
    </a>
  );
}
