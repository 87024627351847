import styles from "./ButtonMain.module.css";
export default function ButtonMain({ children, size, to, transition }) {
  return (
    <button
      className={`${styles.buttonMain} ${styles[size]} ${transition}`}
      to={to}
    >
      {children}
    </button>
  );
}
