import styles from "./Contact.module.css";
import ButtonMain from "components/ButtonMain";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <article className={styles.container}>
      <h3 className={`${styles.titulo}`}>Tem interesse em trabalhar comigo?</h3>

      <p className={`${styles.subtitulo}`}>
        Estou sempre aberta a discutir propostas de trabalhos ou oportunidades
        como freelancer.
      </p>

      <Link to="/contato">
        <ButtonMain size="lg">Fale comigo</ButtonMain>
      </Link>
    </article>
  );
}
