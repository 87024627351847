import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import Inicio from "./paginas/Inicio";
import MenuComponent from "./components/MenuComponent";
import Footer from "components/Footer";
import PageDefault from "components/PageDefault";
import Loading from "components/Loading";

const LazyContactForm = lazy(() => import("components/ContactForm"));
const LazySobreMim = lazy(() => import("paginas/SobreMim"));
const LazyNotFound = lazy(() => import("components/NotFound"));

function AppRoutes() {
  return (
    <BrowserRouter>
      <MenuComponent />

      <Routes>
        <Route path="/" element={<PageDefault />}>
          <Route index element={<Inicio />} />
          <Route
            path="sobremim"
            element={
              <Suspense fallback={<Loading />}>
                <LazySobreMim />
              </Suspense>
            }
          />
          <Route
            path="contato"
            element={
              <Suspense fallback={<Loading />}>
                <LazyContactForm />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <LazyNotFound />
            </Suspense>
          }
        />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default AppRoutes;
