import styles from "./Banner.module.scss";
import minhaFoto from "assets/minha_foto.jpeg";
import ButtonMain from "components/ButtonMain";
import MenuLink from "components/MenuLink";

export default function Banner() {
  return (
    <section className={styles.banner}>
      <div className={`${styles.apresentacao}`}>
        <h1
          className={`${styles.titulo} animate__animated animate__fadeIn animate__delay-1s`}
        >
          <span className={styles.hi}>Olá, eu sou </span>
          <span className={styles.hi}>Carol Bastos. </span>
          <span className={styles.hi}>Desenvolvedora Frontend</span>
        </h1>
        <h2
          className={`${styles.paragrafo} animate__animated animate__fadeIn animate__delay-3s`}
        >
          Aqui falo sobre a minha carreira e compartilho projetos que desenvolvi
          para aprimorar meus conhecimentos.
        </h2>

        <div
          className={`${styles.containerButton} animate__animated animate__fadeIn animate__delay-4s`}
        >
          <MenuLink to="/sobremim">
            <ButtonMain size="lg">Sobre Mim</ButtonMain>
          </MenuLink>
        </div>
      </div>

      <div
        className={`${styles.containerMinhaFoto} animate__animated animate__fadeIn animate__delay-5s`}
      >
        <img
          className={styles.minhaFoto}
          src={minhaFoto}
          alt="Foto de Carol Bastos"
        ></img>
      </div>
    </section>
  );
}
